import { PaginationProps, Table } from "antd";
import { ColumnType } from "antd/lib/table";
import React, { useMemo } from "react";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import styled from "styled-components";
import { BulkImportStatusPageData } from "../../types/BulkImportStatusPageData";
import filterTableRows from "./filterTableRows";
import generateColumns from "./generateColumns";
import useSearchTerm from "./queryParameters";

const RightAlign = styled.div`
    float: right;
`;

type Props = {
    loading: boolean;
    data: BulkImportStatusPageData[];
};

const BulkImportStatusTable: React.FC<Props> = ({ loading, data }) => {
    const [searchTerm] = useSearchTerm();
    const columns: ColumnType<BulkImportStatusPageData>[] = useMemo(() => generateColumns(), []);
    const filteredData: BulkImportStatusPageData[] = useMemo(
        () => filterTableRows(searchTerm, data).map((r) => ({ ...r, key: r.merchantId })),
        [searchTerm, data]
    );

    const rowExpandable = (record: BulkImportStatusPageData) => record.status === "Failed";
    const paginationProps: PaginationProps = {
        disabled: loading,
        showSizeChanger: true,
        total: Math.min(data.length, filteredData.length),
        showTotal: (total, [start, end]) => {
            const description = `Showing ${start} to ${end} of ${total} items`;
            if (data.length === filteredData.length) {
                return description;
            }

            return `${description} (filtered from ${data.length} items)`;
        },
    };

    return (
        <Table
            scroll={{ x: true }}
            loading={loading}
            columns={columns}
            dataSource={filteredData}
            expandable={{
                expandedRowRender: (record) => (
                    <RightAlign>{record.errors?.map((err) => err)}</RightAlign>
                ),
                rowExpandable,
                expandIcon: ({ expanded, expandable, onExpand, record }) =>
                    expanded
                        ? expandable && (
                              <FaCaretUp
                                  style={{ float: "right" }}
                                  onClick={(e) => onExpand(record, e as React.MouseEvent<any>)}
                              />
                          )
                        : expandable && (
                              <FaCaretDown
                                  onClick={(e) => onExpand(record, e as React.MouseEvent<any>)}
                              />
                          ),
                expandIconColumnIndex: columns.length,
            }}
            pagination={paginationProps}
        />
    );
};

export default BulkImportStatusTable;
