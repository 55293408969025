import { Popover, Spin } from "antd";
import React, { useMemo } from "react";
import { FaIdCard, FaRegQuestionCircle } from "react-icons/fa";
import { useParams } from "react-router";
import styled from "styled-components";
import { useGetStoPayStatusQuery } from "../../services";

type RouteParams = {
    corpCode: string;
    locationCode: string;
};

const getActivatedMessage = (activatedBy?: string, activatedOn?: Date) => {
    if (!activatedOn) {
        return "";
    }

    // manual activation
    if (activatedBy) {
        const activated = new Date(activatedOn).toLocaleString("en-us");
        return `. Activated by ${activatedBy} at ${activated}`;
    }

    // auto-activation
    const activatedDate = new Date(activatedOn).toLocaleDateString("en-us");
    return `. Auto-activated on ${activatedDate}`;
};

const getStoPayStatus = (
    paymentInterface: string,
    organizationId?: string,
    merchantId?: string,
    storablePaymentsStatus?: string,
    activatedBy?: string,
    activatedOn?: Date,
    scheduledActivation?: Date
) => {
    if (!organizationId) {
        return { status: "Corp Not Enabled", message: "The corp is not set up for StoPay at all" };
    }

    if (!merchantId) {
        if (!storablePaymentsStatus || storablePaymentsStatus === "0") {
            return {
                status: "Site Not Enabled",
                message: "Some sites in the corp use StoPay, but this one does not",
            };
        }

        if (storablePaymentsStatus === "1") {
            return {
                status: "Site Enabled",
                message: "The site has access to the onboarding wizard but has not started it",
            };
        }
    }

    if (storablePaymentsStatus === "1") {
        return {
            status: "Wizard in Progress",
            message: "The site has access to the wizard and their merchant has been created",
        };
    }

    if (storablePaymentsStatus === "2") {
        const scheduledMessage = scheduledActivation
            ? `. Auto-activation is scheduled for ${new Date(
                  scheduledActivation
              ).toLocaleDateString("en-us")}`
            : "";

        return {
            status: "Wizard Submitted",
            message: `The site has completed the wizard, but has not activated StoPay${scheduledMessage}`,
        };
    }

    if (storablePaymentsStatus === "3" && paymentInterface === "19") {
        return {
            status: "Storable Payments Activated",
            message: `The site is using StoPay as its current processor${getActivatedMessage(
                activatedBy,
                activatedOn
            )}`,
        };
    }

    return {
        status: "Error: Unable to determine the status",
        message: JSON.stringify({ storablePaymentsStatus, paymentInterface }),
    };
};

const BlackLink = styled.a`
    color: black;
`;

const StorablePaymentsStatus: React.FC = () => {
    const { corpCode, locationCode } = useParams<RouteParams>();
    const { isLoading, isFetching, data } = useGetStoPayStatusQuery({ corpCode, locationCode });

    const stoPayStatus = useMemo(
        () =>
            getStoPayStatus(
                data?.paymentInterface ?? "",
                data?.organizationId,
                data?.merchantId,
                data?.storablePaymentsStatus,
                data?.activatedBy,
                data?.activatedOn,
                data?.scheduledActivation
            ),
        [data]
    );

    const createStatusDisplay = (merchantId?: string) => {
        let portalIcon = <></>;
        if (merchantId) {
            // add a link to the Payrix portal because we had a MID available
            const prodMid = merchantId.startsWith("p1_");
            const subdomain = prodMid ? "portal" : "test-portal";
            const url = `https://${subdomain}.payrix.com/merchants/profile/${merchantId}`;
            portalIcon = (
                <Popover content="View merchant in the Payrix Portal" trigger="hover">
                    <BlackLink href={url} target="_blank" rel="noreferrer noopener">
                        <FaIdCard />
                    </BlackLink>
                </Popover>
            );
        }

        return (
            <div>
                {stoPayStatus.status}{" "}
                <Popover content={stoPayStatus.message} trigger="hover">
                    <FaRegQuestionCircle />
                </Popover>{" "}
                {portalIcon}
            </div>
        );
    };

    return (
        <div>
            <div>{isLoading || isFetching ? <Spin /> : createStatusDisplay(data?.merchantId)}</div>
        </div>
    );
};

export default StorablePaymentsStatus;
