import { PaginationProps, Table } from "antd";
import { ColumnType } from "antd/lib/table";
import { TableRowSelection } from "antd/lib/table/interface";
import React, { useMemo } from "react";
import FacilityHomePageData from "../../types/FacilityHomePageData";
import filterTableRows from "./filterTableRows";
import generateColumns from "./generateColumns";
import { useSearchTerm } from "./queryParameters";

export type SelectedFacility = Pick<FacilityHomePageData, "corpCode" | "locationCode">;
const getRowKey = (row: SelectedFacility) => `${row.corpCode}-${row.locationCode}`;

type Props = {
    loading: boolean;
    data: FacilityHomePageData[];
    selectedFacilities: SelectedFacility[];
    onSelectionChange: (rows: SelectedFacility[]) => void;
};

export const FacilitiesTable: React.FC<Props> = ({
    loading,
    data,
    selectedFacilities,
    onSelectionChange,
}) => {
    const [searchTerm] = useSearchTerm();

    const columns: ColumnType<FacilityHomePageData>[] = useMemo(
        () => generateColumns(searchTerm),
        [searchTerm]
    );
    const filteredData: FacilityHomePageData[] = useMemo(
        () => filterTableRows(searchTerm, data),
        [searchTerm, data]
    );

    const paginationProps: PaginationProps = {
        disabled: loading,
        showSizeChanger: true,
        total: Math.min(data.length, filteredData.length),
        showTotal: (total, [start, end]) => {
            const description = `Showing ${start} to ${end} of ${total} items`;
            if (data.length === filteredData.length) {
                return description;
            }

            return `${description} (filtered from ${data.length} items)`;
        },
    };

    const selectedKeys = useMemo(() => selectedFacilities.map(getRowKey), [selectedFacilities]);
    const rowSelection: TableRowSelection<FacilityHomePageData> = {
        selectedRowKeys: selectedKeys,
        onChange: (_, rows) => {
            const updatedSelectedFacilities = rows.map(({ corpCode, locationCode }) => ({
                corpCode,
                locationCode,
            }));
            onSelectionChange(updatedSelectedFacilities);
        },
    };

    return (
        <Table
            loading={loading}
            columns={columns}
            dataSource={filteredData}
            rowKey={getRowKey}
            pagination={paginationProps}
            rowSelection={rowSelection}
        />
    );
};
