import { Col, Row } from "antd";
import React from "react";

const LogoutComplete: React.FC = () => (
    <>
        <Row justify="center">
            <Col>
                <h1>You have been logged out of SiteLink Site Manager.</h1>
            </Col>
        </Row>
        <Row justify="center">
            <Col>
                <a href="/">Return to SiteLink Site Manager</a>
            </Col>
        </Row>
    </>
);

export default LogoutComplete;
