import { Col, Progress, Row, Select, Space } from "antd";
import React from "react";
import { CSVLink } from "react-csv";
import styled from "styled-components";
import { BulkImportStatusPageData, ImportTask } from "../../types/BulkImportStatusPageData";
import BulkImportStatusTable from "./BulkImportStatusTable";
import useFilterValue from "./queryParameters";

const SpacedRow = styled(Row)`
    padding: 1em;
`;
const RightAlign = styled.div`
    float: right;
`;

const enum Status {
    All = "All",
    InProgress = "InProgress",
    Failed = "Failed",
    Completed = "Completed",
}

type Props = {
    loading: boolean;
    data: BulkImportStatusPageData[];
    task: ImportTask | undefined;
};

const BulkImportStatus: React.FC<Props> = ({ loading, data, task }) => {
    const [filterValue, setFilterValue] = useFilterValue();

    const start = task ? new Date(task.createdAt) : new Date();
    const user = task?.userName;
    const siteCount = data?.length;
    const sitesComplete = data?.filter(
        (d) => d.status === "Failed" || d.status === "Completed"
    ).length;
    const percentDone = ((sitesComplete ?? 0) * 100) / Math.max(1, siteCount ?? 1);

    const onStatusFilterChange = (value: string) => setFilterValue(value);

    return (
        <div>
            <SpacedRow>
                <Col span={15}>
                    <p>
                        This data does not display in real time, refresh to see the most current
                        data
                    </p>
                    <p>
                        Import started {start.toLocaleString()} by {user}
                    </p>
                    <Progress
                        percent={percentDone}
                        format={() => `${sitesComplete}/${siteCount} Sites`}
                    />
                </Col>
            </SpacedRow>
            <SpacedRow gutter={16}>
                <Col span={22}>
                    <Space>
                        Status
                        <Select
                            disabled={loading}
                            defaultValue={filterValue}
                            onChange={onStatusFilterChange}
                            // wrapping Select in a styled component breaks type inference
                            style={{ width: "175px" }}
                        >
                            <Select.Option value={Status.All}>All</Select.Option>
                            <Select.Option value={Status.InProgress}>In Progress</Select.Option>
                            <Select.Option value={Status.Failed}>Failed</Select.Option>
                            <Select.Option value={Status.Completed}>Completed</Select.Option>
                        </Select>
                    </Space>
                </Col>
                <Col span={2}>
                    <RightAlign>
                        <CSVLink
                            className="ant-btn ant-btn-primary"
                            target="_blank"
                            filename="bulk-import-merchant.csv"
                            data={data}
                        >
                            Export
                        </CSVLink>
                    </RightAlign>
                </Col>
            </SpacedRow>
            <SpacedRow>
                <Col span={24}>
                    <BulkImportStatusTable loading={loading} data={data} />
                </Col>
            </SpacedRow>
        </div>
    );
};

export default BulkImportStatus;
