import { Col, Row } from "antd";
import React from "react";

const Forbidden: React.FC = () => (
    <>
        <Row justify="center">
            <Col>
                <h1>Unable to access SiteLink Site Manager.</h1>
            </Col>
        </Row>
        <Row justify="center">
            <Col>
                <p>
                    Please try to access the site again later. If the problem persists please{" "}
                    <a href="mailto:help@storable.com">contact the help desk.</a>
                </p>
            </Col>
        </Row>
    </>
);

export default Forbidden;
