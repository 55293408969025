import { Col, Row } from "antd";
import React from "react";
import { useParams } from "react-router";

type RouteParams = {
    corpCode: string;
};

const CorpDetail: React.FC = () => {
    const { corpCode } = useParams<RouteParams>();

    return (
        <Row justify="center">
            <Col>
                <h2>You asked for corp {corpCode}.</h2>
            </Col>
        </Row>
    );
};

export default CorpDetail;
