import { Button, Col, message, Row, Space, Spin } from "antd";
import DatePicker from "antd/lib/date-picker";
import Form from "antd/lib/form";
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
    useGetAutoActivationQuery,
    useUpdateAutoActivationMutation,
} from "../../services/facility";

type RouteParams = {
    corpCode: string;
    locationCode: string;
};

const AutoActivation = () => {
    const { corpCode, locationCode } = useParams<RouteParams>();
    const { isLoading, isFetching, data, refetch } = useGetAutoActivationQuery({
        corpCode,
        locationCode,
    });
    const [updateAutoActivation, { isLoading: isUpdating }] = useUpdateAutoActivationMutation();
    const [activationDate, setActivationDate] = useState<moment.Moment | null>();

    useEffect(() => {
        if (!data) {
            setActivationDate(null);
            return;
        }

        setActivationDate(data.autoActivationDate ? moment(data.autoActivationDate) : null);
    }, [data, isFetching]);

    const onSave = async () => {
        if (activationDate != null && activationDate.isSameOrBefore(new Date())) {
            message.error("Auto-Activation date must be a future date.");
        }

        await updateAutoActivation({
            autoActivationDate: activationDate?.format("YYYY-MM-DD") ?? null,
            corpCode,
            locationCode,
        });
    };

    const onCancel = () => {
        // query for the auto-activation date again to refresh this tab's data
        refetch();
    };

    const onChange = (value: moment.Moment | null) => {
        setActivationDate(value);
    };

    const onDisableDate = (currentDate: moment.Moment) => {
        // do not allow the selection of today or past dates
        return currentDate.isSameOrBefore(new Date());
    };

    return (
        <div>
            <p>
                If you are changing this date, make sure it matches the opportunity’s Close Date in
                Salesforce.
            </p>
            <p>
                Auto-activation takes place around 12:01 AM site local time. A SiteLink Bulletin
                will be automatically posted for this site on the auto-activation date with helpful
                getting started information for Payments.
            </p>
            <p>
                If this site should <b>not</b> be auto-activated, remove the date from the field
                below or leave the field below blank.
            </p>
            <br />
            {(isLoading || isFetching || isUpdating) ?? <Spin />}
            <Form>
                <Row>
                    <Col>
                        <Form.Item>
                            <div>
                                <b>Auto-Activation Date</b>
                            </div>
                            <DatePicker
                                value={activationDate}
                                onChange={onChange}
                                disabledDate={onDisableDate}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="end">
                    <Col>
                        <Form.Item>
                            <Space>
                                <Button onClick={onCancel}>Cancel</Button>
                                <Button type="primary" onClick={onSave}>
                                    Save
                                </Button>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default AutoActivation;
