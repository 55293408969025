import React, { ReactNode } from "react";

type Props = {
    txnId: string;
    text?: ReactNode;
};

const PayrixPortalTxnLink: React.FC<Props> = ({ txnId, text }) => {
    const productionId = txnId.startsWith("p1");
    const domain = productionId ? "portal" : "test-portal";
    const url = `https://${domain}.payrix.com/merchants/payments/${txnId}`;

    return (
        <a href={url} target="_blank" rel="noreferrer noopener">
            {text ?? txnId}
        </a>
    );
};

PayrixPortalTxnLink.defaultProps = { text: undefined };

export default PayrixPortalTxnLink;
