import { TableColumnType } from "antd";
import { intervalToDuration } from "date-fns";
import React from "react";
import UserName from "../../../components/UserName";
import { OrphanedTransactionsReport } from "../../../types";
import { formatDateEst } from "../../../utilities";

const reportColumns: TableColumnType<OrphanedTransactionsReport>[] = [
    {
        title: "Report Id",
        dataIndex: "id",
    },
    {
        title: "Created (EST)",
        dataIndex: "createdAt",
        render: (createdAt: string) => formatDateEst(new Date(createdAt)),
    },
    {
        title: "Created By",
        dataIndex: "createdByUserId",
        render: (createdByUserId: number) => <UserName id={createdByUserId} />,
    },
    {
        title: "Status",
        render: (
            _: unknown,
            { startedAt, completedAt, totalSites, sitesProcessed }: OrphanedTransactionsReport
        ): string => {
            if (!startedAt) {
                return "Pending";
            }

            if (completedAt) {
                return "Completed";
            }

            return `${sitesProcessed} / ${totalSites} Processed`;
        },
    },
    {
        title: "Processing Time",
        render: (_: unknown, { startedAt, completedAt }: OrphanedTransactionsReport): string => {
            if (!startedAt) {
                return "";
            }

            const { hours, minutes, seconds } = intervalToDuration({
                start: new Date(startedAt),
                end: completedAt ? new Date(completedAt) : new Date(),
            });
            const m = (minutes ?? 0).toString().padStart(2, "0");
            const s = (seconds ?? 0).toString().padStart(2, "0");

            const minAndSec = `${m}:${s}`;
            if (!hours) {
                return minAndSec;
            }

            return `${hours}:${minAndSec}`;
        },
    },
    {
        title: "Period (EST)",
        render: (_: unknown, { reportStart, reportEnd }) =>
            `${formatDateEst(new Date(reportStart))} to ${formatDateEst(new Date(reportEnd))}`,
    },
    {
        title: "Sites",
        dataIndex: "totalSites",
    },
    {
        title: "Transactions Found",
        dataIndex: "orphanedTransactionsFound",
    },
];

export default reportColumns;
