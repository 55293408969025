import React from "react";
import { Route, Switch } from "react-router-dom";
import BatchStatus from "../../pages/Batch";
import BulkImport from "../../pages/BulkImport";
import CorpDetail from "../../pages/CorpDetail";
import FacilityDetail from "../../pages/FacilityDetail/FacilityDetail";
import HomePage from "../../pages/Home";
import NotFound from "../../pages/NotFound";
import ReportsPage from "../../pages/Reports";
import {
    OrphanedTransactionsDetailPage,
    OrphanedTransactionsPage,
} from "../../pages/Reports/OrphanedTransactions";

const Routes: React.FC = () => (
    <Switch>
        <Route exact path="/">
            <HomePage />
        </Route>
        <Route exact path="/corps/:corpCode">
            <CorpDetail />
        </Route>
        <Route exact path="/facilities/:corpCode/:locationCode">
            <FacilityDetail />
        </Route>
        <Route exact path="/import/bulk">
            <BulkImport />
        </Route>
        <Route exact path="/import/bulk/:taskId">
            <BulkImport selectedTab="2" />
        </Route>
        <Route exact path="/batch/status">
            <BatchStatus />
        </Route>
        <Route exact path="/reports">
            <ReportsPage />
        </Route>
        <Route exact path="/reports/orphaned-transactions">
            <OrphanedTransactionsPage />
        </Route>
        <Route exact path="/reports/orphaned-transactions/:reportId">
            <OrphanedTransactionsDetailPage />
        </Route>
        <Route path="*">
            <NotFound />
        </Route>
    </Switch>
);

export default Routes;
