import { format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

const easternTimeZone = "America/New_York";

export const timeFormat = "h:mm aaaa";
export const dateFormat = "yyyy-MM-dd h:mm aaaa";
export const dateFormatWithSeconds = "yyyy-MM-dd h:mm:ss aaaa";

export const formatDate = (date: Date): string => format(date, dateFormat);

export const formatDateEst = (date: Date): string =>
    formatInTimeZone(date, easternTimeZone, dateFormat);

export const formatDateWithSeconds = (date: Date): string => format(date, dateFormatWithSeconds);

export const formatDateWithSecondsEst = (date: Date): string =>
    formatInTimeZone(date, easternTimeZone, dateFormatWithSeconds);

export const formatISOWithOffset = (date: Date): string =>
    format(date ?? new Date(), "yyyy-MM-dd'T'HH:mm:ssXX");
