import { FacilityHomePageData } from "../../types";
import baseApi from "../baseApi";
import { FacilityHomePageParams } from "./FacilityHomePageParams";

const facilitiesApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getFacilitiesForHomePage: build.query<FacilityHomePageData[], FacilityHomePageParams>({
            query: (params) => ({
                url: "facilities/homepage",
                params: { ...params },
            }),
            providesTags: ["FacilityHomePage"],
        }),
    }),

    overrideExisting: true,
});

export const { useGetFacilitiesForHomePageQuery } = facilitiesApi;
export * from "./FacilityHomePageParams";
