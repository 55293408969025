import React, { useEffect, useState } from "react";
import {
    CorpSearchFilter,
    LocationSearchFilter,
    useGetFacilitiesForHomePageQuery,
} from "../../services";
import { FacilitiesTable, SelectedFacility } from "./FacilitiesTable";
import { useCorpSearch, useLocationSearch } from "./queryParameters";
import SearchBar from "./SearchBar";

const HomePage: React.FC = () => {
    const [corpSearch] = useCorpSearch();
    const [locationSearch] = useLocationSearch();

    // Any future UI that lets the user take action after selecting multiple facilities will use
    // this list to know what the user selected
    const [selectedFacilities, setSelectedFacilities] = useState<SelectedFacility[]>([]);

    const { isLoading, isFetching, data } = useGetFacilitiesForHomePageQuery({
        corpSearch: corpSearch as CorpSearchFilter,
        locationSearch: locationSearch as LocationSearchFilter,
    });

    // Clear selections any time a data load occurs
    useEffect(() => {
        setSelectedFacilities([]);
    }, [data]);

    return (
        <>
            <h2>All Facilities</h2>
            <SearchBar loading={isLoading || isFetching} />
            <FacilitiesTable
                loading={isLoading || isFetching}
                data={data ?? []}
                selectedFacilities={selectedFacilities}
                onSelectionChange={setSelectedFacilities}
            />
        </>
    );
};

export default HomePage;
