import { Button, Col, Input, PaginationProps, Row, Space, Table, TableProps } from "antd";
import { SortOrder } from "antd/lib/table/interface";
import debounce from "lodash/debounce";
import React, { ChangeEvent, ChangeEventHandler, useCallback, useMemo } from "react";
import { FaFileCsv, FaSearch, FaSync } from "react-icons/fa";
import { useParams } from "react-router";
import styled from "styled-components";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";
import {
    useGetOrphanedTransactionsReportByIdQuery,
    useGetOrphanedTransactionsReportResultsQuery,
} from "../../../services";
import { OrphanedTransaction } from "../../../types";
import NotFound from "../../NotFound";
import filterResultsTableRows from "./filterResultsTableRows";
import reportColumns from "./reportColumns";
import generateResultColumns from "./resultColumns";

const TableHeaderRow = styled(Row)`
    line-height: 64px;
`;

const reportPaginationProps: PaginationProps = {
    hideOnSinglePage: true,
};

type DetailPageProps = {
    reportId: number;
};

const DetailPage: React.FC<DetailPageProps> = ({ reportId }) => {
    const [{ search, sort, sortOrder, page, pageSize }, setQuery] = useQueryParams({
        search: withDefault(StringParam, undefined),
        sort: withDefault(StringParam, undefined),
        sortOrder: withDefault(StringParam, undefined),
        page: withDefault(NumberParam, 1),
        pageSize: withDefault(NumberParam, 10),
    });

    const {
        isFetching: reportFetching,
        isLoading: reportLoading,
        data: reportData,
        refetch: refetchReport,
    } = useGetOrphanedTransactionsReportByIdQuery(reportId);
    const reportReady = !(reportFetching || reportLoading) && reportData;

    const {
        isFetching: resultsFetching,
        isLoading: resultsLoading,
        data: resultsData,
        refetch: refetchResults,
    } = useGetOrphanedTransactionsReportResultsQuery(reportId);
    const resultsReady = !(resultsFetching || resultsLoading) && resultsData;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const searchChange: ChangeEventHandler<HTMLInputElement> = useCallback(
        debounce(
            (e: ChangeEvent<HTMLInputElement>) =>
                setQuery((oldQuery) => ({ ...oldQuery, search: e.target.value })),
            100
        ),
        [setQuery]
    );

    const refetch = useCallback(() => {
        refetchReport();
        refetchResults();
    }, [refetchReport, refetchResults]);

    const resultsColumns = useMemo(
        () =>
            generateResultColumns(
                search ?? null,
                sort ?? "transactionCreated",
                (sortOrder as SortOrder) ?? "ascend"
            ),
        [search, sort, sortOrder]
    );

    const filteredResultsData = useMemo(
        () => filterResultsTableRows(search, resultsData ?? []),
        [search, resultsData]
    );

    const onResultsTableChange: TableProps<OrphanedTransaction>["onChange"] = useCallback(
        (p, f, s) => {
            setQuery((oldQuery) => ({
                ...oldQuery,
                sort: s.columnKey,
                sortOrder: s.order,
            }));
        },
        [setQuery]
    );

    const resultsPaginationProps: PaginationProps = useMemo(
        () => ({
            disabled: !resultsReady,
            defaultCurrent: page,
            defaultPageSize: pageSize,
            showSizeChanger: true,
            onChange: (p, ps) => setQuery((oldQuery) => ({ ...oldQuery, page: p, pageSize: ps })),
            total: Math.min(filteredResultsData.length, resultsData?.length ?? 0),
            showTotal: (total, [start, end]) => {
                const description = `Showing ${start} to ${end} of ${total} items`;
                if (!resultsData || filteredResultsData.length === resultsData.length) {
                    return description;
                }

                return `${description} (filtered from ${resultsData.length} items)`;
            },
        }),
        [resultsReady, page, pageSize, resultsData, filteredResultsData, setQuery]
    );

    return (
        <>
            <h2>Orphaned Transactions Report {reportId}</h2>
            <TableHeaderRow justify="center" gutter={16}>
                <Col>
                    <Input
                        placeholder="Type to search results"
                        defaultValue={search}
                        prefix={<FaSearch />}
                        onChange={searchChange}
                        allowClear
                    />
                </Col>
                <Col>
                    <Button disabled={!reportReady || !resultsReady} onClick={refetch}>
                        <Space>
                            <FaSync />
                            Refresh Data
                        </Space>
                    </Button>
                </Col>
                <Col>
                    <Button
                        disabled={!resultsReady}
                        href={`/api/reports/orphaned-transactions/${reportId}/results-csv`}
                    >
                        <Space>
                            <FaFileCsv />
                            Download CSV
                        </Space>
                    </Button>
                </Col>
            </TableHeaderRow>
            <Table
                loading={!reportReady}
                columns={reportColumns}
                dataSource={reportData ? [reportData] : undefined}
                rowKey="id"
                pagination={reportPaginationProps}
            />
            <Table
                loading={!resultsReady}
                columns={resultsColumns}
                dataSource={filteredResultsData}
                rowKey="transactionId"
                pagination={resultsPaginationProps}
                onChange={onResultsTableChange}
            />
        </>
    );
};

type RouteParams = {
    reportId: string;
};

const OrphanedTransactionsDetailPage: React.FC = () => {
    const { reportId: rawReportId } = useParams<RouteParams>();
    const reportId = parseInt(rawReportId, 10);

    if (Number.isNaN(reportId)) {
        return <NotFound />;
    }

    return <DetailPage reportId={reportId} />;
};

export default OrphanedTransactionsDetailPage;
