import { OrphanedTransaction } from "../../../types";
import { formatDateWithSecondsEst } from "../../../utilities";

const filterResultsTableRows = (
    searchTerm: string | undefined,
    data: OrphanedTransaction[]
): OrphanedTransaction[] => {
    if (!searchTerm) {
        return data;
    }

    const lowerSearchTerm = searchTerm.toLowerCase();
    const matches = (value: string | null) => {
        if (!value) {
            return false;
        }

        return value.toLowerCase().indexOf(lowerSearchTerm) !== -1;
    };

    return data.filter(
        (t) =>
            matches(formatDateWithSecondsEst(new Date(t.transactionCreated))) ||
            matches(t.corpCode) ||
            matches(t.locationCode) ||
            matches(t.merchantId) ||
            matches(t.transactionId) ||
            matches(t.amount.toString()) ||
            matches(t.cardType) ||
            matches(t.cardLastFour) ||
            matches(t.resolution === "None" ? "" : t.resolution) ||
            matches(t.source)
    );
};

export default filterResultsTableRows;
