import React from "react";
import { Link } from "react-router-dom";

const ReportsPage: React.FC = () => {
    return (
        <>
            <h2>SiteLink Reports</h2>
            <h3>Storable Payments</h3>
            <ul>
                <li>
                    <Link to="/reports/orphaned-transactions">Orphaned Transactions</Link>
                </li>
            </ul>
        </>
    );
};

export default ReportsPage;
