import { TableColumnType } from "antd";
import { BatchData } from "../../types/BatchData";
import {
    createDateComparer,
    createNumberComparer,
    createStringComparer,
    formatDate,
} from "../../utilities";

const generateColumns = (): TableColumnType<BatchData>[] => {
    const renderStatusText = (text: string) => {
        let color = "black";
        switch (text) {
            case "Failure":
                color = "red";
                break;
            case "Rerun":
            case "PartialSuccess":
                color = "orange";
                break;
            case "Success":
                color = "green";
                break;
            default:
                color = "black";
        }

        return <span style={{ color }}>{text}</span>;
    };

    const renderDate = (val: string) => (val ? formatDate(new Date(val)) : "");

    return [
        {
            title: "Facility",
            dataIndex: "corpCode",
            sorter: {
                compare: createStringComparer((c) => c.corpCode),
            },
            sortDirections: ["ascend", "descend", "ascend"],
        },
        {
            title: "Source",
            dataIndex: "source",
            sorter: {
                compare: createStringComparer((c) => c.source),
            },
            sortDirections: ["ascend", "descend", "ascend"],
        },
        {
            title: "Job Id",
            dataIndex: "id",
            sorter: {
                compare: createNumberComparer((c) => c.id),
            },
            sortDirections: ["ascend", "descend", "ascend"],
        },
        {
            title: "Job Name",
            dataIndex: "jobName",
            sorter: {
                compare: createStringComparer((c) => c.jobName),
            },
            sortDirections: ["ascend", "descend", "ascend"],
        },
        {
            title: "Database",
            dataIndex: "database",
            sorter: {
                compare: createStringComparer((c) => c.database),
            },
            sortDirections: ["ascend", "descend", "ascend"],
        },
        {
            title: "Started",
            dataIndex: "start",
            defaultSortOrder: "descend",
            sorter: {
                compare: createDateComparer((c) => new Date(c.start)),
            },
            sortDirections: ["ascend", "descend", "ascend"],
            render: (_, { start }) => renderDate(start),
        },
        {
            title: "Ended",
            dataIndex: "end",
            sorter: {
                compare: createDateComparer((c) => new Date(c.end)),
            },
            sortDirections: ["ascend", "descend", "ascend"],
            render: (_, { end }) => renderDate(end),
        },
        {
            title: "Status",
            dataIndex: "lastRunStatus",
            sorter: {
                compare: createStringComparer((c) => c.lastRunStatus),
            },
            sortDirections: ["ascend", "descend", "ascend"],
            render: (_, { lastRunStatus }) => renderStatusText(lastRunStatus),
        },
    ];
};

export default generateColumns;
