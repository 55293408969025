import { NumberParam, StringParam, withDefault } from "use-query-params";

const params = {
    startDate: withDefault(StringParam, undefined),
    endDate: withDefault(StringParam, undefined),
    search: withDefault(StringParam, undefined),
    sort: withDefault(StringParam, undefined),
    sortOrder: withDefault(StringParam, undefined),
    page: withDefault(NumberParam, 1),
    pageSize: withDefault(NumberParam, 10),
};

export default params;
