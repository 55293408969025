export enum CorpSearchFilter {
    Active = "Active",
    Deleted = "Deleted",
    All = "All",
}

export enum LocationSearchFilter {
    Active = "Active",
    Disabled = "Disabled",
    Deleted = "Deleted",
    All = "All",
}

export interface FacilityHomePageParams {
    corpSearch: CorpSearchFilter;
    locationSearch: LocationSearchFilter;
}
