import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export default createApi({
    baseQuery: fetchBaseQuery({ baseUrl: "/api" }),
    tagTypes: [
        "FacilityHomePage",
        "User",
        "Import",
        "ActiveImportTask",
        "FacilityStoPayStatus",
        "FacilityAutoActivation",
        "Facility",
        "ImportMerchants",
        "BatchJobs",
        "BatchJobItems",
        "OrphanedTransactionsReport",
        "OrphanedTransaction",
    ],
    endpoints: () => ({}),
});
