import { TableColumnType } from "antd";
import React from "react";
import { BulkImportStatusPageData } from "../../types/BulkImportStatusPageData";
import { createStringComparer } from "../../utilities";

const generateColumns = (): TableColumnType<BulkImportStatusPageData>[] => {
    const renderStatusText = (text: string) => {
        let color = "black";
        if (text === "Failed") {
            color = "red";
        } else if (text === "Completed") {
            color = "green";
        }

        return <span style={{ color }}>{text}</span>;
    };

    return [
        {
            title: "CORP",
            dataIndex: "corpCode",
            defaultSortOrder: "ascend",
            sorter: {
                compare: createStringComparer((c) => c.corpCode),
                multiple: 2,
            },
        },
        {
            title: "LOC",
            dataIndex: "locationCode",
            defaultSortOrder: "ascend",
            sorter: {
                compare: createStringComparer((c) => c.locationCode),
                multiple: 1,
            },
        },
        {
            title: "New_Payrix_MID",
            dataIndex: "merchantId",
            sorter: createStringComparer((c) => c.merchantId),
        },
        {
            title: "Discount_Group",
            dataIndex: "discountGroup",
            sorter: createStringComparer((c) => c.discountGroup),
        },
        {
            title: "Auth_Group",
            dataIndex: "authGroup",
            sorter: createStringComparer((c) => c.authGroup),
        },
        {
            title: "Monthly_Group",
            dataIndex: "monthlyGroup",
            sorter: createStringComparer((c) => c.monthlyGroup),
        },
        {
            title: "Annual_Group",
            dataIndex: "annualGroup",
            sorter: createStringComparer((c) => c.annualGroup),
        },
        {
            title: "Auto_Activation_Date",
            dataIndex: "autoActivationDate",
            sorter: createStringComparer((c) => c.autoActivationDate),
            render: (_, { autoActivationDate }) => {
                if (!autoActivationDate) {
                    return <span />;
                }

                const d = new Date(autoActivationDate);
                return <span>{d.toLocaleDateString()}</span>;
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            sorter: createStringComparer((c) => c.status),
            render: renderStatusText,
        },
    ];
};

export default generateColumns;
