import { Layout } from "antd";
import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import Forbidden from "../../pages/Forbidden";
import LogoutComplete from "../../pages/LogoutComplete";
import Header from "./Header";
import { BodyContent, BodyLayout } from "./index.styles";
import LeftNav from "./LeftNav";
import Routes from "./Routes";

const App: React.FC = () => (
    <BrowserRouter>
        <QueryParamProvider ReactRouterRoute={Route}>
            <Switch>
                <Route exact path="/authentication/logout-complete">
                    <LogoutComplete />
                </Route>
                <Route exact path="/authentication/forbidden">
                    <Forbidden />
                </Route>
                <Route path="*">
                    <Layout>
                        <Header />
                        <Layout>
                            <LeftNav />
                            <BodyLayout>
                                <BodyContent>
                                    <Routes />
                                </BodyContent>
                            </BodyLayout>
                        </Layout>
                    </Layout>
                </Route>
            </Switch>
        </QueryParamProvider>
    </BrowserRouter>
);

export default App;
