import { Skeleton } from "antd";
import React from "react";
import { useGetUserQuery } from "../services";

type Props = {
    id: number;
};

const UserName: React.FC<Props> = ({ id }) => {
    const { isLoading, isFetching, data } = useGetUserQuery(id);

    if (isLoading || isFetching || !data) {
        return <Skeleton paragraph={false} />;
    }

    return <>{data.name}</>;
};

export default UserName;
