import { Modal } from "antd";
import { useCallback } from "react";
import { useParams } from "react-router";
import { useDeactivateMerchantAccountMutation } from "../../services/facility";

type RouteParams = {
    corpCode: string;
    locationCode: string;
};

type Props = {
    visible: boolean;
    hideModal: () => void;
};

const OpenOnboardingModal: React.FC<Props> = ({ visible, hideModal }) => {
    const { corpCode, locationCode } = useParams<RouteParams>();
    const [openOnboarding, { isLoading }] = useDeactivateMerchantAccountMutation();

    const onOk = useCallback(async () => {
        await openOnboarding({ corpCode, locationCode });

        hideModal();
        window.location.reload();
    }, [openOnboarding, corpCode, locationCode, hideModal]);

    const onCancel = useCallback(() => {
        hideModal();
    }, [hideModal]);

    return (
        <Modal
            visible={visible}
            confirmLoading={isLoading}
            onOk={onOk}
            onCancel={onCancel}
            okText="Yes, Continue"
            cancelText="No, Cancel"
        >
            <p>
                <b>Are you sure you want to open onboarding for this site? </b>
            </p>
            <p>This action cannot be undone.</p>
        </Modal>
    );
};
export default OpenOnboardingModal;
