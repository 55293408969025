import { Menu, MenuProps } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { FaFile, FaFileImport, FaHome, FaMoneyBill, FaTasks } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { LeftNavMenu, LeftNavSider } from "./LeftNav.styles";

interface MenuItem {
    key: string;
    name: string;
    url?: string;
    icon?: React.ReactFragment;
    subItems?: MenuItem[];
}

const LeftNav: React.FC = () => {
    const { pathname } = useLocation();
    const [currentKey, setCurrentKey] = useState("home");
    const [openKeys, setOpenKeys] = useState([] as string[]);
    const menuItems: MenuItem[] = useMemo(
        () => [
            {
                key: "home",
                name: "All Facilities",
                url: "/",
                icon: <FaHome />,
            },
            {
                key: "payments",
                name: "Payments",
                icon: <FaMoneyBill />,
                subItems: [
                    {
                        key: "bulk-import",
                        name: "Bulk Import",
                        url: "/import/bulk",
                        icon: <FaFileImport />,
                    },
                ],
            },
            {
                key: "batch",
                name: "Batch",
                icon: <FaTasks />,
                subItems: [
                    {
                        key: "batch-status",
                        name: "Status",
                        url: "/batch/status",
                    },
                ],
            },
            {
                key: "reports",
                name: "Reports",
                url: "/reports",
                icon: <FaFile />,
            },
        ],
        []
    );

    useEffect(() => {
        const currentItem = menuItems.find((item) => item.url === pathname);
        if (currentItem) {
            setCurrentKey(currentItem.key);
        } else {
            const subItem = menuItems.find(
                (item) => item.subItems && item.subItems.find((sub) => sub.url === pathname)
            );
            if (subItem) {
                setOpenKeys([subItem.key]);
                subItem.subItems?.forEach((s) => {
                    if (s.url === pathname) {
                        setCurrentKey(s.key);
                    }
                });
            }
        }
    }, [menuItems, pathname]);
    const onClickHandler: MenuProps["onClick"] = (e) => {
        setCurrentKey(e.key);
    };
    const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key.toString()) === -1);
        if (
            !menuItems.find(
                (item) => item.subItems && item.subItems.find((sub) => sub.url === pathname)
            )
        ) {
            setOpenKeys(keys.map((k) => k.toString()));
        } else {
            setOpenKeys(latestOpenKey?.toString() ? [latestOpenKey.toString()] : []);
        }
    };

    return (
        <LeftNavSider width={200} collapsible>
            <LeftNavMenu
                mode="inline"
                theme="dark"
                selectedKeys={[currentKey]}
                openKeys={openKeys}
                onClick={onClickHandler}
                onOpenChange={onOpenChange}
            >
                {menuItems.map((item) =>
                    item.subItems ? (
                        <Menu.SubMenu key={item.key} title={item.name} icon={item.icon}>
                            {item.subItems.map((subItem) => (
                                <Menu.Item key={subItem.key} icon={subItem.icon}>
                                    {subItem.url && <Link to={subItem.url}>{subItem.name}</Link>}
                                </Menu.Item>
                            ))}
                        </Menu.SubMenu>
                    ) : (
                        <Menu.Item key={item.key} icon={item.icon}>
                            {item.url && <Link to={item.url}>{item.name}</Link>}
                        </Menu.Item>
                    )
                )}
            </LeftNavMenu>
        </LeftNavSider>
    );
};

export default LeftNav;
