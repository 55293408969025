import { Col, Input, Space } from "antd";
import { RangePickerProps } from "antd/es/date-picker/generatePicker";
import { debounce } from "lodash";
import { ChangeEvent, ChangeEventHandler, useCallback } from "react";
import { FaSearch } from "react-icons/fa";
import { useQueryParams } from "use-query-params";
import DatePicker from "../../components/DatePicker";
import { dateFormat, formatISOWithOffset, timeFormat } from "../../utilities";
import { SearchInputRow } from "./SearchBar.styles";
import params from "./queryParameters";

type Props = {
    loading: boolean;
};

const SearchBar: React.FC<Props> = ({ loading }) => {
    const [{ startDate, endDate, search }, setQuery] = useQueryParams(params);
    const onDateChange = (value: RangePickerProps<Date>["value"]) => {
        const start = value ? value[0] : null;
        const end = value ? value[1] : null;
        setQuery((oldQuery) => ({
            ...oldQuery,
            startDate: formatISOWithOffset(start ?? new Date()),
            endDate: formatISOWithOffset(end ?? new Date()),
        }));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const searchChange: ChangeEventHandler<HTMLInputElement> = useCallback(
        debounce(
            (e: ChangeEvent<HTMLInputElement>) =>
                setQuery((oldQuery) => ({ ...oldQuery, search: e.target.value })),
            100
        ),
        [setQuery]
    );

    const addHours = (hours: number, date = new Date()) => {
        date.setTime(date.getTime() + hours * 60 * 60 * 1000);
        return date;
    };

    return (
        <SearchInputRow gutter={16}>
            <Col>
                <Space>
                    Date Range:
                    <DatePicker.RangePicker
                        disabled={loading}
                        showTime={{ format: timeFormat }}
                        defaultValue={[
                            startDate ? new Date(startDate) : addHours(-4),
                            endDate ? new Date(endDate) : addHours(1),
                        ]}
                        format={dateFormat}
                        onChange={onDateChange}
                    />
                </Space>
            </Col>
            <Col>
                <Input
                    defaultValue={search}
                    prefix={<FaSearch />}
                    onChange={searchChange}
                    allowClear
                />
            </Col>
        </SearchInputRow>
    );
};

export default SearchBar;
