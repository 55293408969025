import { Checkbox, Col, Modal, Row } from "antd";
import { RangePickerProps } from "antd/es/date-picker/generatePicker";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import DatePicker from "../../../components/DatePicker";
import { useCreateOrphanedTransactionsReportMutation } from "../../../services";
import { dateFormat, timeFormat } from "../../../utilities";

const FormRow = styled(Row)`
    line-height: 64px;
`;

type Props = {
    visible: boolean;
    hideModal: () => void;
};

const minimumDate = new Date(2020, 1, 1);
const defaultFormValues = {
    start: "",
    end: "",
    emailResults: false,
    isRunByUser: true,
};

const CreateReportModal: React.FC<Props> = ({ visible, hideModal }) => {
    const [createReport, { isLoading }] = useCreateOrphanedTransactionsReportMutation();
    const [formValues, setFormValues] = useState(defaultFormValues);

    const onOk = useCallback(async () => {
        await createReport(formValues);
        setFormValues(defaultFormValues);
        hideModal();
    }, [createReport, formValues, setFormValues, hideModal]);

    const onCancel = useCallback(() => {
        setFormValues(defaultFormValues);
        hideModal();
    }, [setFormValues, hideModal]);

    const dateRangeChange = useCallback(
        (value: RangePickerProps<Date>["value"]) => {
            const [start, end] = value ?? [undefined, undefined];
            const params =
                start && end
                    ? { start: start.toISOString(), end: end.toISOString() }
                    : { start: "", end: "" };

            setFormValues((oldValues) => ({ ...oldValues, ...params }));
        },
        [setFormValues]
    );

    const emailResultsChange = useCallback(
        (e: CheckboxChangeEvent) =>
            setFormValues((oldValues) => ({ ...oldValues, emailResults: e.target.checked })),
        [setFormValues]
    );

    return (
        <Modal
            title="Create Orphaned Transactions Report"
            visible={visible}
            confirmLoading={isLoading}
            okButtonProps={{ disabled: !(formValues.start && formValues.end) }}
            onOk={onOk}
            onCancel={onCancel}
        >
            <FormRow justify="center" gutter={16}>
                <Col>
                    <DatePicker.RangePicker
                        showTime={{ format: timeFormat }}
                        format={dateFormat}
                        onChange={dateRangeChange}
                        disabledDate={(d) => d < minimumDate || d > new Date()}
                        value={
                            formValues.start && formValues.end
                                ? [new Date(formValues.start), new Date(formValues.end)]
                                : undefined
                        }
                    />
                </Col>
            </FormRow>
            <FormRow justify="center" gutter={16}>
                <Col>
                    <Checkbox onChange={emailResultsChange} checked={formValues.emailResults}>
                        Email results to me
                    </Checkbox>
                </Col>
            </FormRow>
        </Modal>
    );
};

export default CreateReportModal;
