import styled, { css } from "styled-components";

const headerText = css`
    color: #fffffff0;
`;

export const AppTitle = styled.h1`
    ${headerText}
`;

export const UserName = styled.strong`
    ${headerText}
`;

export const ProfilePic = styled.img`
    max-height: 45px;
    width: auto;
`;

export const LogoutLink = styled.a`
    ${headerText}
`;
