import { User } from "../types";
import baseApi from "./baseApi";

const usersApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getCurrentUser: build.query<User, void>({
            query: () => "users/current",
            providesTags: (result) => {
                if (!result) {
                    return [];
                }

                return [{ type: "User" as const, id: result.id }];
            },
            keepUnusedDataFor: 60 * 60,
        }),

        getUser: build.query<User, number>({
            query: (id) => `users/${id}`,
            providesTags: (result, error, id) => [{ type: "User" as const, id }],
        }),
    }),

    overrideExisting: true,
});

export const { useGetCurrentUserQuery, useGetUserQuery } = usersApi;
