import { Layout } from "antd";
import styled from "styled-components";

export const BodyLayout = styled(Layout)`
    padding: 0;
`;

export const BodyContent = styled(Layout.Content)`
    background-color: #fff;
    padding: 20px;
    margin: 0;
`;
