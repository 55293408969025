import { Space, TableColumnType } from "antd";
import React from "react";
import { FaEyeSlash, FaTrash } from "react-icons/fa";
import { Link } from "react-router-dom";
import HighlightSearchTerm from "../../components/HighlightSearchTerm";
import FacilityHomePageData from "../../types/FacilityHomePageData";
import { createStringComparer } from "../../utilities";

const generateColumns = (searchTerm: string): TableColumnType<FacilityHomePageData>[] => {
    const renderSearchableText = (text: string) => (
        <HighlightSearchTerm searchTerm={searchTerm} text={text} />
    );

    return [
        {
            title: "Corp Code",
            dataIndex: "corpCode",
            defaultSortOrder: "ascend",
            sorter: {
                compare: createStringComparer((c) => c.corpCode),
                multiple: 2,
            },
            render: (_, { corpCode, corpDeleted }) => (
                <Space>
                    {corpDeleted && <FaTrash title={`${corpCode} has been deleted`} />}
                    <Link to={`/corps/${corpCode}`}>
                        <HighlightSearchTerm searchTerm={searchTerm} text={corpCode} />
                    </Link>
                </Space>
            ),
        },
        {
            title: "Location Code",
            dataIndex: "locationCode",
            defaultSortOrder: "ascend",
            sorter: {
                compare: createStringComparer((c) => c.locationCode),
                multiple: 1,
            },
            render: (_, { corpCode, locationCode, locationDisabled, locationDeleted }) => (
                <Space>
                    {locationDisabled && (
                        <FaEyeSlash title={`${corpCode} ${locationCode} has been disabled`} />
                    )}
                    {locationDeleted && (
                        <FaTrash title={`${corpCode} ${locationCode} has been deleted`} />
                    )}
                    <Link to={`/facilities/${corpCode}/${locationCode}`}>
                        <HighlightSearchTerm searchTerm={searchTerm} text={locationCode} />
                    </Link>
                </Space>
            ),
        },
        {
            title: "Company",
            dataIndex: "corpCompany",
            sorter: createStringComparer((c) => c.corpCompany),
            render: renderSearchableText,
        },
        {
            title: "Facility Name",
            dataIndex: "locationName",
            sorter: createStringComparer((c) => c.locationName),
            render: renderSearchableText,
        },
        {
            title: "Contact",
            dataIndex: "locationContactName",
            sorter: createStringComparer((c) => c.locationContactName),
            render: renderSearchableText,
        },
        {
            title: "Email",
            dataIndex: "locationEmailAddress",
            sorter: createStringComparer((c) => c.locationEmailAddress),
            render: renderSearchableText,
        },
        {
            title: "Phone",
            dataIndex: "locationPhone",
            sorter: createStringComparer((c) => c.locationPhone),
            render: renderSearchableText,
        },
        {
            title: "Address",
            render: (
                _,
                {
                    locationAddress1,
                    locationAddress2,
                    locationCity,
                    locationRegion,
                    locationPostalCode,
                    locationCountry,
                }
            ) => (
                <>
                    <HighlightSearchTerm searchTerm={searchTerm} text={locationAddress1} />
                    <br />
                    {locationAddress2 && (
                        <>
                            <HighlightSearchTerm searchTerm={searchTerm} text={locationAddress2} />
                            <br />
                        </>
                    )}
                    <HighlightSearchTerm searchTerm={searchTerm} text={locationCity} />,{" "}
                    <HighlightSearchTerm searchTerm={searchTerm} text={locationRegion} />
                    {locationPostalCode && (
                        <>
                            {" "}
                            <HighlightSearchTerm
                                searchTerm={searchTerm}
                                text={locationPostalCode}
                            />
                        </>
                    )}
                    {locationCountry && (
                        <>
                            {" "}
                            <HighlightSearchTerm searchTerm={searchTerm} text={locationCountry} />
                        </>
                    )}
                </>
            ),
        },
        {
            title: "Database",
            dataIndex: "transactionDatabaseName",
            sorter: createStringComparer((c) => c.transactionDatabaseName),
            render: renderSearchableText,
        },
    ];
};

export default generateColumns;
