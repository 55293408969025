import { Col, Layout, Row, Skeleton } from "antd";
import React from "react";
import { useGetCurrentUserQuery } from "../../services";
import { AppTitle, LogoutLink, ProfilePic, UserName } from "./Header.styles";

const CurrentUser: React.FC = () => {
    const { isLoading, isFetching, data } = useGetCurrentUserQuery();

    if (isLoading || isFetching || !data) {
        return <Skeleton paragraph={false} />;
    }

    return (
        <Row justify="end" gutter={16}>
            <Col>
                <UserName>{data.name}</UserName>
            </Col>
            <Col>
                <ProfilePic
                    src={data.profilePictureUrl}
                    alt={data.name}
                    referrerPolicy="no-referrer"
                />
            </Col>
            <Col>
                <LogoutLink href="/authentication/logout">Log Out</LogoutLink>
            </Col>
        </Row>
    );
};

const Header: React.FC = () => (
    <Layout.Header>
        <Row>
            <Col flex={1}>
                <AppTitle>SiteLink Site Manager</AppTitle>
            </Col>
            <Col flex={1}>
                <CurrentUser />
            </Col>
        </Row>
    </Layout.Header>
);

export default Header;
