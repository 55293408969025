/* eslint-disable import/prefer-default-export */
import { BatchData, BatchDataReport } from "../../types/BatchData";
import baseApi from "../baseApi";
import { BatchItemsParams, BatchStatusParams } from "./BatchParams";

const batchApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getBatches: build.query<BatchData[], BatchStatusParams>({
            query: (params) => ({
                url: "batch/status",
                params: { ...params },
            }),
            providesTags: ["BatchJobs"],
        }),
        getBatchItems: build.query<BatchDataReport[], BatchItemsParams>({
            query: (params) => ({
                url: "batch/items",
                method: "get",
                params: { ...params },
            }),
            providesTags: ["BatchJobItems"],
        }),
    }),

    overrideExisting: true,
});

export const { useGetBatchesQuery, useGetBatchItemsQuery, useLazyGetBatchItemsQuery } = batchApi;
export * from "./BatchParams";
