import { FacilityHomePageData } from "../../types";

const filterTableRows = (
    searchTerm: string,
    data: FacilityHomePageData[]
): FacilityHomePageData[] => {
    if (!searchTerm) {
        return data;
    }

    const matches = (value: string | null): boolean => {
        if (!value) {
            return false;
        }

        return value.toLowerCase().indexOf(searchTerm) !== -1;
    };

    return data.filter(
        (r) =>
            matches(r.corpCode) ||
            matches(r.locationCode) ||
            matches(r.corpCompany) ||
            matches(r.locationName) ||
            matches(r.locationContactName) ||
            matches(r.locationEmailAddress) ||
            matches(r.locationPhone) ||
            matches(r.locationAddress1) ||
            matches(r.locationAddress2) ||
            matches(r.locationCity) ||
            matches(r.locationPostalCode) ||
            matches(r.locationCountry) ||
            matches(r.locationRegion) ||
            matches(r.transactionDatabaseName)
    );
};

export default filterTableRows;
