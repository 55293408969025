import { PaginationProps, Table } from "antd";
import { ColumnType, TableProps } from "antd/lib/table";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQueryParams } from "use-query-params";
import { useGetBatchesQuery, useLazyGetBatchItemsQuery } from "../../services/batch";
import { BatchData, BatchDataReport } from "../../types/BatchData";
import SearchBar from "./SearchBar";
import filterResultsTableRows from "./filterResultsTableRows";
import generateColumns from "./generateColumns";
import params from "./queryParameters";

const getRowKey = (row: BatchData) => `${row.id}-${row.corpCode}-${row.source}`;
const BatchStatus: React.FC = () => {
    const [{ startDate, endDate, search, page, pageSize }, setQuery] = useQueryParams(params);

    const columns: ColumnType<BatchData>[] = generateColumns();
    const [batchItemInfo, setBatchItemInfo] = useState({} as Record<number, BatchDataReport[]>);

    const { isLoading, isFetching, data, error } = useGetBatchesQuery({
        start: startDate,
        end: endDate,
    });
    const [triggerBatchItemQuery, { isItemLoading, isItemFetching, data: batchItemData }] =
        useLazyGetBatchItemsQuery({
            selectFromResult: ({
                data: itemData,
                originalArgs,
                isLoading: loading,
                isFetching: fetching,
            }) => ({
                data: { batchId: originalArgs?.batchId ?? 0, batchItems: itemData },
                isItemLoading: loading,
                isItemFetching: fetching,
            }),
        });

    const filteredResultsData = useMemo(
        () => filterResultsTableRows(search, data ?? []),
        [search, data]
    );

    const renderReportDetails = (record: BatchData) => {
        const col: ColumnType<BatchDataReport>[] = [
            { dataIndex: "reportName" },
            { dataIndex: "message" },
        ];

        return (
            <Table
                columns={col}
                loading={isItemLoading || isItemFetching}
                dataSource={
                    record.source === "HubBatch" ? batchItemInfo[record.id] : record.reports
                }
                rowKey={(row) => row.id}
                pagination={false}
            />
        );
    };

    const fetchBatchItems = (expanded: boolean, record: BatchData) => {
        if (!expanded || batchItemInfo[record.id] || record.source !== "HubBatch") {
            return;
        }

        const parts = record.corpCode.split("-");
        triggerBatchItemQuery({ corpCode: parts[0], locationCode: parts[1], batchId: record.id });
    };

    useEffect(() => {
        setBatchItemInfo((prev) => ({
            ...prev,
            [batchItemData.batchId]: batchItemData.batchItems ?? [],
        }));
    }, [batchItemData.batchId, batchItemData.batchItems]);

    const paginationChange: PaginationProps["onChange"] = useCallback(
        (p, ps) => {
            setQuery((oldQuery) => ({
                ...oldQuery,
                page: p,
                pageSize: ps,
            }));
        },
        [setQuery]
    );

    const paginationProps: PaginationProps = {
        disabled: isLoading,
        current: page,
        pageSize,
        showSizeChanger: true,
        total: Math.min(data?.length ?? 0, filteredResultsData.length),
        showTotal: (total, [start, end]) => {
            const description = `Showing ${start} to ${end} of ${total} items`;
            if (data?.length === filteredResultsData?.length) {
                return description;
            }

            return `${description} (filtered from ${data?.length} items)`;
        },
        onChange: paginationChange,
    };

    const tableChange: TableProps<BatchData>["onChange"] = useCallback(
        (p, f, s) => {
            setQuery((oldQuery) => ({
                ...oldQuery,
                sort: s.columnKey,
                sortOrder: s.order,
            }));
        },
        [setQuery]
    );

    return (
        <>
            {isItemFetching}, {isItemLoading}
            <h2>Current Batch Status</h2>
            <SearchBar loading={isLoading || isFetching} />
            {error && JSON.stringify(error)}
            <Table
                loading={isLoading || isFetching}
                columns={columns}
                dataSource={filteredResultsData}
                rowKey={getRowKey}
                expandable={{
                    expandedRowRender: renderReportDetails,
                    rowExpandable: () => true,
                    onExpand: fetchBatchItems,
                }}
                onChange={tableChange}
                pagination={paginationProps}
            />
        </>
    );
};
export default BatchStatus;
