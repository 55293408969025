/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { CorpSearchFilter, LocationSearchFilter } from "../../services";

export const useCorpSearch = () =>
    useQueryParam("corps", withDefault(StringParam, CorpSearchFilter.Active));

export const useLocationSearch = () =>
    useQueryParam("locations", withDefault(StringParam, LocationSearchFilter.Active));

export const useSearchTerm = () => useQueryParam("search", withDefault(StringParam, ""));
