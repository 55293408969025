import { configureStore, isRejectedWithValue, Middleware } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import baseApi from "./services/baseApi";

const errorHandlerMiddleware: Middleware = () => (next) => (action) => {
    if (!isRejectedWithValue(action)) {
        return next(action);
    }

    if (action.payload.status !== 401) {
        // TODO: Show a toast or something fun
        return next(action);
    }

    // User isn't logged in, punt them to the login page
    const { pathname, search } = window.location;
    const returnUrl = encodeURIComponent(pathname + search);
    window.location.href = `/authentication/google-login?returnUrl=${returnUrl}`;
    return undefined;
};

const store = configureStore({
    reducer: {
        [baseApi.reducerPath]: baseApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(baseApi.middleware, errorHandlerMiddleware),
});

setupListeners(store.dispatch);

export default store;
