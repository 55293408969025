import { Layout, Menu } from "antd";
import styled from "styled-components";

export const LeftNavSider = styled(Layout.Sider)`
    min-height: calc(100vh - 64px);
`;

export const LeftNavMenu = styled(Menu)`
    height: 100%;
    border-right: 5px;
`;
