import { Button, Col, Row } from "antd";
import React, { EventHandler, SyntheticEvent } from "react";
import { useHistory, useLocation } from "react-router";

const NotFound: React.FC = () => {
    const { pathname } = useLocation();
    const history = useHistory();

    const goBack: EventHandler<SyntheticEvent<unknown>> = (e) => {
        e.preventDefault();
        history.goBack();
    };

    return (
        <>
            <Row justify="center">
                <Col>
                    <h2>Oops! The page at {pathname} was not found.</h2>
                </Col>
            </Row>
            <Row justify="center">
                <Col>
                    <Button type="link" onClick={goBack}>
                        Go Back
                    </Button>
                </Col>
            </Row>
        </>
    );
};

export default NotFound;
