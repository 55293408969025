import { OrphanedTransactionsReport, OrphanedTransactionsReportsPage } from "../../types";
import OrphanedTransaction from "../../types/reports/OrphanedTransaction";
import baseApi from "../baseApi";
import CreateOrphanedTransactionReportParams from "./CreateOrphanedTransactionsReportParams";
import OrphanedTransactionReportsParams from "./OrphanedTransactionReportsParams";

const orphanedTransactionsReportApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getOrphanedTransactionsReports: build.query<
            OrphanedTransactionsReportsPage,
            OrphanedTransactionReportsParams
        >({
            query: (params) => ({
                url: "reports/orphaned-transactions",
                params: { ...params },
            }),
            providesTags: (result) => {
                if (!result) {
                    return [];
                }

                return [
                    ...result.data.map(({ id }) => ({
                        type: "OrphanedTransactionsReport" as const,
                        id,
                    })),
                    { type: "OrphanedTransactionsReport" as const, id: "LIST" },
                ];
            },
        }),

        getOrphanedTransactionsReportById: build.query<OrphanedTransactionsReport, number>({
            query: (id) => `reports/orphaned-transactions/${id}`,
            providesTags: (result, error, id) => [
                { type: "OrphanedTransactionsReport" as const, id },
            ],
        }),

        createOrphanedTransactionsReport: build.mutation<
            OrphanedTransactionsReport,
            CreateOrphanedTransactionReportParams
        >({
            query: (data) => ({
                url: "reports/orphaned-transactions",
                method: "post",
                body: { ...data, openSalesforceCase: false },
            }),
            invalidatesTags: [{ type: "OrphanedTransactionsReport" as const, id: "LIST" }],
        }),

        getOrphanedTransactionsReportResults: build.query<OrphanedTransaction[], number>({
            query: (id) => `reports/orphaned-transactions/${id}/results`,
            providesTags: (result, error, id) => {
                if (!result) {
                    return [];
                }

                return result.map(({ transactionId }) => ({
                    type: "OrphanedTransaction",
                    id: `${id}-${transactionId}`,
                }));
            },
        }),
    }),

    overrideExisting: true,
});

export const {
    useGetOrphanedTransactionsReportsQuery,
    useGetOrphanedTransactionsReportByIdQuery,
    useCreateOrphanedTransactionsReportMutation,
    useGetOrphanedTransactionsReportResultsQuery,
} = orphanedTransactionsReportApi;
