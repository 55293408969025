import { BatchData } from "../../types/BatchData";

const filterResultsTableRows = (searchTerm: string | undefined, data: BatchData[]): BatchData[] => {
    if (!searchTerm) {
        return data;
    }

    const lowerSearchTerm = searchTerm.toLowerCase();
    const matches = (value: string | null) => {
        if (!value) {
            return false;
        }

        return value.toLowerCase().indexOf(lowerSearchTerm) !== -1;
    };

    return data.filter(
        (t) =>
            matches(t.corpCode) ||
            matches(t.source) ||
            matches(t.id.toString()) ||
            matches(t.jobName) ||
            matches(t.database) ||
            matches(t.lastRunStatus)
    );
};

export default filterResultsTableRows;
