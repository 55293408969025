import { BulkImportStatusPageData } from "../../types/BulkImportStatusPageData";

const filterTableRows = (
    searchTerm: string,
    data: BulkImportStatusPageData[]
): BulkImportStatusPageData[] => {
    if (!searchTerm || searchTerm === "All") {
        return data;
    }

    const matches = (value: string | null): boolean => {
        if (!value) {
            return false;
        }

        return value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1;
    };

    return data?.filter((r) => matches(r.status));
};

export default filterTableRows;
