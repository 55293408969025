import { BulkImportStatusPageData, ImportTask } from "../../../types/BulkImportStatusPageData";
import ImportMerchantResponse from "../../../types/ImportMerchantResponse";
import baseApi from "../../baseApi";

const importApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getSitesByTaskId: build.query<BulkImportStatusPageData[], number>({
            query: (id) => ({
                url: `import/bulk/${id}`,
            }),
            providesTags: (result, error, id) => [{ type: "Import" as const, id }],
        }),
        getActiveTask: build.query<ImportTask, void>({
            query: () => ({
                url: "import/bulk/active",
            }),
            providesTags: ["ActiveImportTask"],
        }),
        importMerchants: build.mutation<ImportMerchantResponse, string>({
            query: (data) => ({
                url: "import/bulk/upload",
                method: "post",
                body: { base64Data: data },
                invalidatesTags: ["ActiveImportTask"],
            }),
        }),
    }),

    overrideExisting: true,
});

export const { useGetSitesByTaskIdQuery, useGetActiveTaskQuery, useImportMerchantsMutation } =
    importApi;
