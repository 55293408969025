import React, { ReactNode } from "react";

type Props = {
    merchantId: string;
    text?: ReactNode;
};

const PayrixPortalMerchantLink: React.FC<Props> = ({ merchantId, text }) => {
    const productionId = merchantId.startsWith("p1");
    const domain = productionId ? "portal" : "test-portal";
    const url = `https://${domain}.payrix.com/merchants/profile/${merchantId}`;

    return (
        <a href={url} target="_blank" rel="noreferrer noopener">
            {text ?? merchantId}
        </a>
    );
};

PayrixPortalMerchantLink.defaultProps = { text: undefined };

export default PayrixPortalMerchantLink;
