import { Col, Input, Select, Space } from "antd";
import debounce from "lodash/debounce";
import React, { ChangeEventHandler, useCallback } from "react";
import { FaSearch } from "react-icons/fa";
import { CorpSearchFilter, LocationSearchFilter } from "../../services";
import { useCorpSearch, useLocationSearch, useSearchTerm } from "./queryParameters";
import { SearchInputRow } from "./SearchBar.styles";

type Props = {
    loading: boolean;
};

const SearchBar: React.FC<Props> = ({ loading }) => {
    const [corpSearch, setCorpSearch] = useCorpSearch();
    const [locationSearch, setLocationSearch] = useLocationSearch();
    const [searchTerm, setSearchTerm] = useSearchTerm();

    const corpSearchChange = (value: string) => setCorpSearch(value);
    const locationSearchChange = (value: string) => setLocationSearch(value);

    // Debounce the search updates to prevent the table from lagging if the user types rapidly
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const setSearchWithDebounce = useCallback(
        debounce((term: string) => setSearchTerm(term.toLowerCase()), 100),
        [setSearchTerm]
    );
    const searchChange: ChangeEventHandler<HTMLInputElement> = (e) =>
        setSearchWithDebounce(e.target.value);

    return (
        <SearchInputRow justify="center" gutter={16}>
            <Col span={6}>
                <Input
                    disabled={loading}
                    placeholder="Type to search"
                    defaultValue={searchTerm}
                    prefix={<FaSearch />}
                    onChange={searchChange}
                    allowClear
                />
            </Col>
            <Col span={4} offset={1}>
                <Space>
                    Viewing Corps:
                    <Select
                        disabled={loading}
                        defaultValue={corpSearch}
                        onChange={corpSearchChange}
                        // wrapping Select in a styled component breaks type inference
                        style={{ width: "100px" }}
                    >
                        <Select.Option value={CorpSearchFilter.Active}>Active</Select.Option>
                        <Select.Option value={CorpSearchFilter.Deleted}>Deleted</Select.Option>
                        <Select.Option value={CorpSearchFilter.All}>All</Select.Option>
                    </Select>
                </Space>
            </Col>
            <Col span={4}>
                <Space>
                    Viewing Locations:
                    <Select
                        disabled={loading}
                        defaultValue={locationSearch}
                        onChange={locationSearchChange}
                        // wrapping Select in a styled component breaks type inference
                        style={{ width: "100px" }}
                    >
                        <Select.Option value={LocationSearchFilter.Active}>Active</Select.Option>
                        <Select.Option value={LocationSearchFilter.Disabled}>
                            Disabled
                        </Select.Option>
                        <Select.Option value={LocationSearchFilter.Deleted}>Deleted</Select.Option>
                        <Select.Option value={LocationSearchFilter.All}>All</Select.Option>
                    </Select>
                </Space>
            </Col>
        </SearchInputRow>
    );
};

export default SearchBar;
