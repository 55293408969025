import { Button, Checkbox, Col, Input, Modal, PaginationProps, Row, Space, Table } from "antd";
import { RangePickerProps } from "antd/es/date-picker/generatePicker";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import debounce from "lodash/debounce";
import React, { ChangeEvent, ChangeEventHandler, useCallback, useMemo, useState } from "react";
import { FaFileInvoiceDollar, FaQuestionCircle, FaSearch, FaSync } from "react-icons/fa";
import { useHistory } from "react-router";
import styled from "styled-components";
import {
    BooleanParam,
    NumberParam,
    StringParam,
    useQueryParams,
    withDefault,
} from "use-query-params";
import DatePicker from "../../../components/DatePicker";
import { useGetOrphanedTransactionsReportsQuery } from "../../../services";
import { dateFormat, timeFormat } from "../../../utilities";
import CreateReportModal from "./CreateReportModal";
import reportColumns from "./reportColumns";

const TableHeaderRow = styled(Row)`
    line-height: 64px;
`;

const showHelpModal = () => {
    Modal.info({
        title: "Orphaned Transactions Report Help",
        content: (
            <>
                <p>
                    This report identifies Storable Payments credit card transactions that were
                    processed successfully but were not recorded in SiteLink.
                </p>
                <p>Click any row to view the transactions found in the report.</p>
                <p>
                    Searching by report period will show all reports that included that time period.
                </p>
            </>
        ),
    });
};

const OrphanedTransactionsReportPage: React.FC = () => {
    const history = useHistory();
    const [showCreateModal, setShowCreateModal] = useState(false);

    const [query, setQuery] = useQueryParams({
        createdByOrReportId: withDefault(StringParam, undefined),
        start: withDefault(StringParam, undefined),
        end: withDefault(StringParam, undefined),
        foundTransactions: withDefault(BooleanParam, undefined),
        page: withDefault(NumberParam, 1),
        pageSize: withDefault(NumberParam, 10),
    });

    const {
        isLoading,
        isFetching,
        data: currentPage,
        refetch,
    } = useGetOrphanedTransactionsReportsQuery(query);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const searchChange: ChangeEventHandler<HTMLInputElement> = useCallback(
        debounce(
            (e: ChangeEvent<HTMLInputElement>) =>
                setQuery((oldQuery) => ({ ...oldQuery, createdByOrReportId: e.target.value })),
            100
        ),
        [setQuery]
    );

    const dateRangeChange = useCallback(
        (value: RangePickerProps<Date>["value"]) => {
            const [start, end] = value ?? [undefined, undefined];
            const params =
                start && end
                    ? { start: start.toISOString(), end: end.toISOString() }
                    : { start: undefined, end: undefined };

            setQuery((oldQuery) => ({ ...oldQuery, ...params }));
        },
        [setQuery]
    );

    const foundTransactionsChange = useCallback(
        (e: CheckboxChangeEvent) =>
            setQuery((oldQuery) => ({ ...oldQuery, foundTransactions: e.target.checked })),
        [setQuery]
    );

    const paginationProps: PaginationProps = useMemo(() => {
        const filteredRecords = currentPage?.filteredRecords ?? 0;
        const totalRecords = currentPage?.totalRecords ?? 0;

        return {
            disabled: isLoading || isFetching,
            defaultCurrent: query.page,
            defaultPageSize: query.pageSize,
            showSizeChanger: true,
            onChange: (page, pageSize) => setQuery((oldQuery) => ({ ...oldQuery, page, pageSize })),
            total: Math.min(filteredRecords, totalRecords),
            showTotal: (total, [start, end]) => {
                const description = `Showing ${start} to ${end} of ${total} items`;
                if (filteredRecords === totalRecords) {
                    return description;
                }

                return `${description} (filtered from ${totalRecords} items)`;
            },
        };
    }, [isFetching, isLoading, query, setQuery, currentPage]);

    return (
        <>
            <h2>Orphaned Transactions Report</h2>
            <Row justify="center" gutter={16}>
                <Col>
                    <Button type="primary" onClick={() => setShowCreateModal(true)}>
                        <Space>
                            <FaFileInvoiceDollar />
                            New Report
                        </Space>
                    </Button>
                </Col>
                <Col>
                    <Button type="default" disabled={isLoading || isFetching} onClick={refetch}>
                        <Space>
                            <FaSync />
                            Refresh Data
                        </Space>
                    </Button>
                </Col>
                <Col>
                    <Button type="default" onClick={showHelpModal}>
                        <Space>
                            <FaQuestionCircle />
                            Help
                        </Space>
                    </Button>
                </Col>
            </Row>
            <TableHeaderRow justify="center" gutter={16}>
                <Col>
                    <Input
                        placeholder="Report Id or User Name"
                        defaultValue={query.createdByOrReportId}
                        prefix={<FaSearch />}
                        onChange={searchChange}
                        allowClear
                    />
                </Col>
                <Col>
                    <Space>
                        Report Period:
                        <DatePicker.RangePicker
                            showTime={{ format: timeFormat }}
                            value={
                                query.start && query.end
                                    ? [new Date(query.start), new Date(query.end)]
                                    : undefined
                            }
                            format={dateFormat}
                            onChange={dateRangeChange}
                        />
                    </Space>
                </Col>
                <Col>
                    <Checkbox checked={query.foundTransactions} onChange={foundTransactionsChange}>
                        Transactions Found
                    </Checkbox>
                </Col>
            </TableHeaderRow>
            <Table
                loading={isLoading || isFetching}
                columns={reportColumns}
                dataSource={currentPage?.data}
                rowKey="id"
                pagination={paginationProps}
                onRow={({ id }) => ({
                    onClick: () => history.push(`/reports/orphaned-transactions/${id}`),
                })}
            />
            <CreateReportModal
                visible={showCreateModal}
                hideModal={() => setShowCreateModal(false)}
            />
        </>
    );
};

export default OrphanedTransactionsReportPage;
