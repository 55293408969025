import React from "react";
import Highlighter from "react-highlight-words";
import HighlightedText from "./HighlightedText";

type Props = {
    searchTerm: string | null;
    text: string;
};

const HighlightSearchTerm: React.FC<Props> = ({ searchTerm, text }) => {
    if (!searchTerm) {
        return <>{text}</>;
    }

    return (
        <Highlighter
            searchWords={[searchTerm]}
            textToHighlight={text}
            highlightTag={HighlightedText}
            autoEscape
        />
    );
};

export default HighlightSearchTerm;
