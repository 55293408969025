import { TableColumnType } from "antd";
import { SortOrder } from "antd/lib/table/interface";
import { Link } from "react-router-dom";
import HighlightSearchTerm from "../../../components/HighlightSearchTerm";
import PayrixPortalMerchantLink from "../../../components/PayrixPortalMerchantLink";
import PayrixPortalTxnLink from "../../../components/PayrixPortalTxnLink";
import { OrphanedTransaction } from "../../../types";
import {
    createNumberComparer,
    createStringComparer,
    formatDateWithSecondsEst,
} from "../../../utilities";

const generateResultColumns = (
    searchTerm: string | null,
    sort: string,
    sortOrder: SortOrder
): TableColumnType<OrphanedTransaction>[] => {
    const renderSearchableText = (text: string) => (
        <HighlightSearchTerm searchTerm={searchTerm} text={text} />
    );

    const generateCommonProps = (
        propertyName: string
    ): Partial<TableColumnType<OrphanedTransaction>> => ({
        dataIndex: propertyName,
        key: propertyName,
        sortOrder: sort === propertyName ? sortOrder : null,
    });

    return [
        {
            ...generateCommonProps("transactionCreated"),
            title: "Transaction Date (EST)",
            defaultSortOrder: "ascend",
            sorter: createStringComparer((r) => r.transactionCreated),
            render: (transactionCreated: string) =>
                renderSearchableText(formatDateWithSecondsEst(new Date(transactionCreated))),
        },
        {
            ...generateCommonProps("corpCode"),
            title: "Corp Code",
            sorter: createStringComparer((r) => r.corpCode),
            render: (corpCode: string) => (
                <Link to={`/corps/${corpCode}`}>
                    <HighlightSearchTerm searchTerm={searchTerm} text={corpCode} />
                </Link>
            ),
        },
        {
            ...generateCommonProps("locationCode"),
            title: "Location Code",
            sorter: createStringComparer((r) => r.locationCode),
            render: (_: unknown, { corpCode, locationCode }) => (
                <Link to={`/facilities/${corpCode}/${locationCode}`}>
                    <HighlightSearchTerm searchTerm={searchTerm} text={locationCode} />
                </Link>
            ),
        },
        {
            ...generateCommonProps("merchantId"),
            title: "MID",
            sorter: createStringComparer((r) => r.merchantId),
            render: (merchantId: string) => (
                <PayrixPortalMerchantLink
                    merchantId={merchantId}
                    text={<HighlightSearchTerm searchTerm={searchTerm} text={merchantId} />}
                />
            ),
        },
        {
            ...generateCommonProps("transactionId"),
            title: "TransactionId",
            sorter: createStringComparer((r) => r.transactionId),
            render: (transactionId: string) => (
                <PayrixPortalTxnLink
                    txnId={transactionId}
                    text={<HighlightSearchTerm searchTerm={searchTerm} text={transactionId} />}
                />
            ),
        },
        {
            ...generateCommonProps("amount"),
            title: "Transaction Amount",
            sorter: createNumberComparer((r) => r.amount),
            render: (amount: number) => renderSearchableText(amount.toFixed(2)),
        },
        {
            ...generateCommonProps("cardType"),
            title: "Type",
            sorter: createStringComparer((r) => r.cardType),
            render: renderSearchableText,
        },
        {
            ...generateCommonProps("cardLastFour"),
            title: "Last 4",
            sorter: createStringComparer((r) => r.cardLastFour),
            render: renderSearchableText,
        },
        {
            ...generateCommonProps("cardholderName"),
            title: "CardHolder",
            sorter: createStringComparer((r) => r.cardholderName),
            render: renderSearchableText,
        },
        {
            ...generateCommonProps("resolution"),
            title: "Resolution",
            sorter: createStringComparer((r) => r.resolution),
            render: (value: string) => renderSearchableText(value === "None" ? "" : value),
        },
        {
            ...generateCommonProps("source"),
            title: "Source",
            sorter: createStringComparer((r) => r.source),
            render: renderSearchableText,
        },
    ];
};

export default generateResultColumns;
