export const compareStrings = (
    a: string | null | undefined,
    b: string | null | undefined
): number => (a ?? "").localeCompare(b ?? "");

export const createStringComparer =
    <T>(selector: (x: T) => string | null | undefined) =>
    (a: T, b: T): number =>
        compareStrings(selector(a), selector(b));

export const compareNumbers = (
    a: number | null | undefined,
    b: number | null | undefined
): number => {
    const aHasValue = a !== null && a !== undefined;
    const bHasValue = b !== null && b !== undefined;

    if (!aHasValue && !bHasValue) {
        return 0;
    }

    if (!aHasValue) {
        return -1;
    }

    if (!bHasValue) {
        return 1;
    }

    const epsilon = 1e-8;
    if (Math.abs(b - a) < epsilon) {
        return 0;
    }

    return a < b ? -1 : 1;
};

export const createNumberComparer =
    <T>(selector: (x: T) => number | null | undefined) =>
    (a: T, b: T): number =>
        compareNumbers(selector(a), selector(b));

export const compareDates = (a: Date | null | undefined, b: Date | null | undefined): number => {
    if (!a && !b) {
        return 0;
    }

    if (!a) {
        return -1;
    }

    if (!b) {
        return 1;
    }

    return Math.max(-1, Math.min((a ?? new Date()).getTime() - (b ?? new Date()).getTime(), 1));
};

export const createDateComparer =
    <T>(selector: (x: T) => Date | null | undefined) =>
    (a: T, b: T): number =>
        compareDates(selector(a), selector(b));
