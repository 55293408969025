import { Tabs } from "antd";
import React from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import BulkImportTab from "../components/BulkImport/BulkImportTab";
import BulkImportStatus from "../components/BulkImportStatus/BulkImportStatus";
import {
    useGetActiveTaskQuery,
    useGetSitesByTaskIdQuery,
} from "../services/stopay/bulkImport/import";

const ContentMargin = styled.div`
    margin: 0em 5em;
`;

type Props = {
    selectedTab?: string | undefined;
};
type RouteParams = {
    taskId: string;
};

const BulkImport: React.FC<Props> = ({ selectedTab }) => {
    const { taskId } = useParams<RouteParams>();
    const { isLoading, isFetching, data } = useGetSitesByTaskIdQuery(parseInt(taskId, 10));
    const { isLoading: activeTaskLoading, data: activeTask } = useGetActiveTaskQuery();

    return (
        <div>
            <h2>Bulk Import</h2>
            <ContentMargin>
                <Tabs defaultActiveKey={selectedTab}>
                    <Tabs.TabPane tab="Import" key="1">
                        <BulkImportTab
                            activeTaskLoading={activeTaskLoading}
                            activeTask={activeTask}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Import Progress" key="2" disabled={taskId === undefined}>
                        <BulkImportStatus
                            loading={isLoading || isFetching}
                            data={data ?? []}
                            task={activeTask}
                        />
                    </Tabs.TabPane>
                </Tabs>
            </ContentMargin>
        </div>
    );
};

BulkImport.defaultProps = { selectedTab: "1" };
export default BulkImport;
