import { Button, Col, Row, Space } from "antd";
import Form from "antd/lib/form";
import { useState } from "react";
import OpenOnboardingModal from "./OpenOnboardingModal";

const Onboarding = () => {
    const [showCreateModal, setShowCreateModal] = useState(false);

    return (
        <>
            <div>
                <p>
                    If you need to open MyHub Corporate Control Center onboarding for this site so
                    the merchant can input new onboarding information, click the button below and
                    the <b>Get Started</b> button will appear under Bank Information for this site.
                </p>
                <p>
                    The merchant will be unable to process credit cards until the onboarding process
                    is complete and a new MID has been assigned.
                </p>
                <br />
                <Row>
                    <Col>
                        <Form.Item>
                            <Space>
                                <Button type="primary" onClick={() => setShowCreateModal(true)}>
                                    Open Onboarding For This Site
                                </Button>
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </div>
            <OpenOnboardingModal
                visible={showCreateModal}
                hideModal={() => setShowCreateModal(false)}
            />
        </>
    );
};

export default Onboarding;
