import FacilityAutoActivationData from "../../types/FacilityAutoActivationData";
import FacilityStoPayStatusData from "../../types/FacilityStoPayStatusData";
import baseApi from "../baseApi";
import { DeactivateMerchantAccountData } from "./DeactivateMerchantAccountData";
import { FacilityAutoActivationParams } from "./FacilityAutoActivationParams";
import { FacilityParams } from "./FacilityParams";

const facilityApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getStoPayStatus: build.query<FacilityStoPayStatusData, FacilityParams>({
            query: (params) => ({
                url: "facilities/stopaystatus",
                params: { ...params },
            }),
            providesTags: ["FacilityStoPayStatus"],
        }),
        getAutoActivation: build.query<FacilityAutoActivationData, FacilityParams>({
            query: (params) => ({
                url: "facilities/autoactivation",
                params: { ...params },
            }),
            providesTags: ["FacilityAutoActivation"],
        }),
        updateAutoActivation: build.mutation<
            FacilityAutoActivationData,
            FacilityAutoActivationParams
        >({
            query: (params) => ({
                url: "facilities/autoactivation",
                method: "PUT",
                body: { ...params },
            }),
        }),
        deactivateMerchantAccount: build.mutation<DeactivateMerchantAccountData, FacilityParams>({
            query: (params) => ({
                url: "facilities/rewrite",
                method: "POST",
                body: { corpCode: params.corpCode, locCode: params.locationCode },
            }),
        }),
    }),

    overrideExisting: true,
});

export const {
    useGetStoPayStatusQuery,
    useGetAutoActivationQuery,
    useUpdateAutoActivationMutation,
    useDeactivateMerchantAccountMutation,
} = facilityApi;
export * from "./FacilityParams";
