import { Col, Row, Tabs } from "antd";
import React from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useGetStoPayStatusQuery } from "../../services/facility";
import AutoActivation from "./AutoActivation";
import Onboarding from "./Onboarding";
import StorablePaymentsStatus from "./StorablePaymentsStatus";

type RouteParams = {
    corpCode: string;
    locationCode: string;
};

const FacilityDetail: React.FC = () => {
    const { corpCode, locationCode } = useParams<RouteParams>();
    const { isLoading, isFetching, data } = useGetStoPayStatusQuery({ corpCode, locationCode });

    const hideActivation =
        isLoading ||
        isFetching ||
        (data?.storablePaymentsStatus === "3" && data?.paymentInterface === "19");
    const hideOnboarding =
        isLoading ||
        isFetching ||
        (data?.storablePaymentsStatus === "1" && data?.paymentInterface === "0");

    return (
        <Row>
            <Col>
                <div>
                    <Link to="/">{"<<"} Back to list</Link>
                </div>
                <br />
                <h1>
                    Corp Code {corpCode} {">"} Location Code {locationCode}
                </h1>
                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab="Payments" key="1">
                        <h2>Storable Payments Status</h2>
                        <StorablePaymentsStatus />
                    </Tabs.TabPane>
                </Tabs>
                <br />

                <Tabs defaultActiveKey="1">
                    {!hideActivation && (
                        <Tabs.TabPane tab="Activation" key="1">
                            <h2>Activation</h2>
                            <AutoActivation />
                        </Tabs.TabPane>
                    )}

                    {!hideOnboarding && (
                        <Tabs.TabPane tab="Onboarding" key="2">
                            <h2>Payments Onboarding</h2>
                            <Onboarding />
                        </Tabs.TabPane>
                    )}
                </Tabs>
            </Col>
        </Row>
    );
};

export default FacilityDetail;
