import { Alert, Button, Col, Row, Upload, message } from "antd";
import { RcFile } from "antd/lib/upload";
import React, { useState } from "react";
import { FaUpload } from "react-icons/fa";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useImportMerchantsMutation } from "../../services";
import { ImportTask } from "../../types/BulkImportStatusPageData";

type Props = {
    activeTaskLoading: boolean;
    activeTask: ImportTask | undefined;
};

const BulkImportTab: React.FC<Props> = ({ activeTaskLoading, activeTask }) => {
    const [importedFile, setImportedFile] = useState("");
    const [errors, setErrors] = useState([] as string[]);
    const [importMerchant, { isLoading: isImporting, isError, isSuccess }] =
        useImportMerchantsMutation();

    const RightAlign = styled.div`
        float: right;
    `;
    const Spaced = styled.div`
        padding: 1em;
    `;

    const getBase64 = (data: RcFile) =>
        new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(data);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });

    const onBeforeUpload = (file: RcFile) => {
        // prevent drag and dropping other file types
        const isCsv = file.type === "text/csv" || file.type === "application/vnd.ms-excel";
        if (!isCsv) {
            message.error(`Error: ${file.name} is not a csv file`);
            return Upload.LIST_IGNORE;
        }

        getBase64(file!).then((data) => setImportedFile(data));
        setErrors([]);
        return false;
    };

    const onImport = () => {
        if (!importedFile) {
            message.error(`Error: You must upload a .csv file before importing`);
            return;
        }

        importMerchant(importedFile)
            .unwrap()
            .then((response) => setErrors(response.errors));
    };
    const importText = (
        <>
            An import is currently{" "}
            <Link to={`/import/bulk/${activeTask?.taskId}`}>in progress</Link>. Please wait until
            that import is complete before importing another file.
        </>
    );

    return (
        <div>
            <Spaced>
                {!activeTaskLoading && activeTask && <Alert message={importText} type="warning" />}
            </Spaced>
            <a href={`${process.env.PUBLIC_URL}/doc/bulk-import-merchant-example.csv`}>
                Sample Import CSV File
            </a>
            <br />
            <br />
            <p>
                This import will make sites ready for Payments activation. The site will either
                auto-activate on the date provided or the merchant can self activate.
            </p>
            <p>
                After this import is run successfully, the customer will notice the &quot;Credit
                Card Setup&quot; button is no longer available.
            </p>
            <p>
                Please note large imports (such as a thousand sites or more) could take hours to
                successfully import. You will be able to see the progress of the import after
                clicking the &quot;Import&quot; button.
            </p>
            <br />
            <Row>
                <Col span={8}>
                    <Upload beforeUpload={onBeforeUpload} multiple={false} maxCount={1}>
                        <Button icon={<FaUpload />}>&nbsp;Upload</Button>
                    </Upload>
                </Col>
            </Row>
            <Row>
                <Col span={8}>
                    <ul>
                        {errors.length > 0 && (
                            <Alert
                                message={errors.map((error) => (
                                    <li key={Math.random()}>{error}</li>
                                ))}
                                type="error"
                            />
                        )}
                    </ul>

                    {isError && <Alert message="Failed to start import process" type="error" />}
                    {isSuccess && errors?.length === 0 && (
                        <Alert message="Import process started successfully" type="success" />
                    )}
                </Col>
            </Row>
            <br />
            <RightAlign>
                <Button
                    type="primary"
                    onClick={onImport}
                    disabled={!!activeTask || importedFile.length === 0 || isImporting}
                >
                    Import
                </Button>
            </RightAlign>
        </div>
    );
};

export default BulkImportTab;
